import React, { useState, useEffect } from 'react';
import { Col, Select, Row, Checkbox, Button, Typography } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

export default function BuyGiftCard(props) {
  let { data } = props.location;
  if (!data) {
    data = {
      name: 'Aargauer Kunsthaus',
      address: 'Aargauerpl., 5001 Aarau, Switzerland',
      giftCardURL: null,
      placeURL: 'http://www.aargauerkunsthaus.ch/',
      emailContact: 'lay-matti@web.de',
      imageURL:
        'https://lh3.googleusercontent.com/p/AF1QipNjG813V9rfY0N1sX_AMsPeOhnWMkFCXpuwrF4=s1600-w800',
      placeID: 'ChIJRw19K-k7kEcRyz4D-_Gd6Rs',
      area: 'AG',
      lat: 47.3900216,
      lng: 8.044644599999998,
    };
  }

  console.log(data);

  const placeInfo = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <img
          src={data.imageURL}
          alt='place'
          style={{ maxWidth: 250, margin: '0 auto' }}></img>
        <br />
        <p>{data.place}</p>
        <p>{data.address}</p>
        <a href={data.placeURL}>{data.placeURL}</a>
      </div>
    );
  };
  const buyGiftCardComp = () => {};

  return (
    <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
      <Row>
        <Title level={1} style={{ color: 'white' }}>
          Give a Gift - Receive a gift card at a discount
        </Title>
      </Row>

      <div className='main-results' style={{ padding: 15 }}>
        <Row justify='center'>
          <Col span={12}>{placeInfo()}</Col>
          <Col span={12}>{buyGiftCardComp}</Col>
        </Row>
      </div>
    </div>
  );
}

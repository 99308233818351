import React from 'react';
import { Button, Row, Col, Typography, Table } from 'antd';
import Skeleton from 'react-loading-skeleton';
import { CallToActionButton } from './CallToActionButton';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

export class SuggestedPlaceCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLat: props.selectedArea.lat,
      currentLng: props.selectedArea.lng,
    };
  }

  render() {
    const GoogleMapExample = withGoogleMap(props => {
      const markers = suggs => {
        return suggs.map(sug => {
          return (
            <Marker
              key={sug.placeID}
              position={{ lat: sug.lat, lng: sug.lng }}></Marker>
          );
        });
      };
      return (
        <GoogleMap
          defaultCenter={{ lat: props.lat, lng: props.lng }}
          defaultZoom={13}>
          {markers(props.suggestions)}
        </GoogleMap>
      );
    });

    /* this.props.suggestedPlaces.map(suggestion => (
      <div key={suggestion.placeID} className='suggested-place'>
        <Row
          onClick={event => {
            this.setState({
              currentLat: suggestion.lat,
              currentLng: suggestion.lng,
            });
          }}
          style={{
            backgroundSize: 'cover',
            position: 'relative',
            backgroundImage: 'url(' + suggestion.imageURL + ')',
            minHeight: '80px',
          }}></Row>
        <Row style={{ minHeight: '88px' }}>
          <Title
            className='suggestion-title'
            style={{
              textAlign: 'center',
              width: '100%',
              marginTop: '12px',
              padding: '0px 6px',
            }}
            level={4}>
            {suggestion.name}
          </Title>
          <div style={{ margin: '0px auto' }}>
            <CallToActionButton place={suggestion} size={'default'} />
          </div>
        </Row>
      </div> */

    const { Title } = Typography;
    var suggestedPlaceCards;
    if (this.props.suggestedPlaces && this.props.suggestedPlaces.length) {
      suggestedPlaceCards = this.props.suggestedPlaces.map(suggestion => (
        <div
          key={suggestion.placeID}
          className='suggested-place'
          onClick={() => {
            this.setState({
              currentLag: suggestion.lat,
              currentLng: suggestion.lng,
            });
          }}>
          <Row
            style={{
              backgroundSize: 'cover',
              position: 'relative',
              backgroundImage: 'url(' + suggestion.imageURL + ')',
              minHeight: '80px',
            }}></Row>
          <Row style={{ minHeight: '88px' }}>
            <Title
              className='suggestion-title'
              style={{
                textAlign: 'center',
                width: '100%',
                marginTop: '12px',
                padding: '0px 6px',
              }}
              level={4}>
              {suggestion.name}
            </Title>
            <div style={{ margin: '0px auto' }}>
              <Button
                className='button-buy'
                onClick={() =>
                  this.props.hist.push({
                    pathname: '/buyGiftCard',
                    data: suggestion,
                  })
                }>
                Gutsein Gutschein
              </Button>
            </div>
          </Row>
        </div>
      ));
    } else {
      suggestedPlaceCards = [...Array(6).keys()].map(placeholder => (
        <div key={placeholder} className='suggested-place'>
          <div style={{ marginTop: -4 }}>
            <Skeleton height={168} />
          </div>
        </div>
      ));
    }

    return (
      <div ref={this.props.passRef}>
        <section className='suggestions-container'>
          <Row style={{ justifyContent: 'center' }}>
            <Col xs={22} md={12}>
              {suggestedPlaceCards}
            </Col>
            <Col xs={22} md={{ span: 11, offset: 1 }}>
              <div>
                <GoogleMapExample
                  suggestions={this.props.suggestedPlaces}
                  lat={this.state.currentLat}
                  lng={this.state.currentLng}
                  containerElement={<div className='map-container' />}
                  mapElement={
                    <div style={{ height: `100%`, background: 'black' }} />
                  }
                />
              </div>
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}

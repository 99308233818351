import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Button, Typography, message } from 'antd';
import axios from 'axios';
import GoogleAddressInput from './CustomInputs';
import { useHistory } from 'react-router-dom';
import './formStyle.scss';

const { Title } = Typography;
const { Option } = Select;

export default function RegisterPlace() {
  const [Industries, setIndustries] = useState([]);
  const [Areas, setAreas] = useState([]);
  const onSubmit = data => {
    console.log(data);
  }; // your form submit function which will invoke after successful validation

  const functions = [
    'Besitzer',
    'Manager',
    'Chef',
    'Berater',
    'Operations/IT',
    'Lieferant',
    'Technologie Partner',
    'Anderes',
  ];

  useEffect(() => {
    axios.get('/api/places/industries').then(result => {
      setIndustries(result.data.Industries);
    });
    axios.get('/api/places/areas', {}).then(
      result => {
        console.log(result.data.Areas);
        setAreas(result.data.Areas);
      },
      error => {
        console.log(error);
      },
    );
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 5,
      },
    },
  };

  const SubmitForm = props => {
    const [form] = Form.useForm();
    const hist = useHistory();

    const onFinish = values => {
      axios
        .post('/api/places/register_new_place', {
          ...values,
        })
        .then(response => {
          message.success('Registrierung abgesendet');
          hist.push('/');
        })
        .catch(error => {
          message.error('Fehler beim absenden!');
          console.log(error);
        });
    };

    return (
      <Form
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        scrollToFirstError>
        <Form.Item
          name='placeName'
          label='Firmen Name'
          rules={[
            {
              required: true,
              message: 'Please input the Name!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='placeAddress'
          label='Firmen Adresse'
          rules={[
            {
              required: true,
              message: 'Please input the address!',
            },
          ]}>
          <GoogleAddressInput></GoogleAddressInput>
        </Form.Item>

        <Form.Item name='placeWebsite' label='Firmen Webseite'>
          <Input />
        </Form.Item>

        <Form.Item
          name='area'
          label='Bereich'
          rules={[
            {
              required: true,
              message: 'Please select the area',
            },
          ]}>
          <Select>
            {props.areas.map(ar => {
              return <Select.Option value={ar.key}>{ar.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name='industry'
          label='Art'
          rules={[
            {
              required: true,
              message: 'Please select the type',
            },
          ]}>
          <Select>
            {props.industries.map(ar => {
              return <Select.Option value={ar.key}>{ar.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='submitterName'
          label='Dein Name'
          rules={[
            {
              required: true,
              message: 'Please input your Name!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='email'
          label='Deine E-mail'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item name='phoneNo' label='Tel. Nr.'>
          <Input />
        </Form.Item>

        <Form.Item
          name='submitterPosition'
          label='Deine Funktion'
          rules={[
            {
              required: true,
              message: 'Please select the type',
            },
          ]}>
          <Select>
            {functions.map(func => {
              return <Select.Option value={func}>{func}</Select.Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit'>
            Hinzufügen
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
      <Row>
        <Title level={1} style={{ color: 'white' }}>
          Füge Dein Geschäft hinzu
        </Title>
      </Row>
      <Row>
        <p style={{ color: 'white' }}>
          Füge die Informationen Deines Geschäfts hinzu, um Deine Gäste zu
          ermutigen, Dein Unternehmen zu unterstützen. Nachdem Du Deine
          Informationen eingegeben haben, erhältst Du eine Bestätigungs-E-Mail,
          wenn Dein Geschäft dem Verzeichnis hinzugefügt wird.
        </p>
      </Row>
      <div className='main-results' style={{ padding: 15 }}>
        <SubmitForm areas={Areas} industries={Industries}></SubmitForm>
      </div>
    </div>
  );
}

import './App.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Popover, Typography, Row, Col } from 'antd';

import Config from './Config';
import GoogleAnalyticsTag from './Components/GoogleAnalyticsTag';
import FAQModal from './Components/FAQModal';
import LogEngagementEvent from './Logging';
import AreaCards from './Components/AreaCards';
import PlaceFilterDisplay from './Components/PlaceFilterDisplay';
import ShareOptions from './Components/ShareOptions';
import img from './img/logo.jpeg';
import SubmitPlace from './Components/Forms/SubmitPlace';
import RegisterPlace from './Components/Forms/RegisterPlace';
import BuyGiftCard from './Components/BuyGiftCard';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.baseURL = 'https://185.46.57.133';

const { Title } = Typography;

/*
 * This holds the context of what area (eg, SF, East Bay, etc)
 * is currently enabled
 */
const AreaContext = React.createContext({
  currentArea: null,
  updateArea: () => {},
});

class App extends React.Component {
  constructor(props) {
    super(props);
    // TODO this is janktown routing
    const path = window.location.pathname.slice(1).toLowerCase();

    this.state = {
      addPlaceVisible: path === 'addplace',
    };

    this.selfRef = React.createRef();
  }

  componentDidMount() {}

  render() {
    return (
      <Router>
        <div>
          <div style={{ marginTop: '0px' }}>
            <Header showFaq={this.showFAQModal} />
            <Row className='hero-row'>
              <Switch>
                <Route path='/submitPlace'>
                  <SubmitPlace></SubmitPlace>
                </Route>
                <Route path='/registerPlace'>
                  <RegisterPlace></RegisterPlace>
                </Route>
                <Route
                  path='/buyGiftCard'
                  component={props => <BuyGiftCard {...props} />}></Route>
                <Route path='/'>
                  <Main></Main>
                </Route>
              </Switch>
            </Row>
          </div>
        </div>
      </Router>
    );
  }
}

function Main(props) {
  const [Areas, setAreas] = useState([]);
  const history = useHistory();

  useEffect(() => {
    axios.get('/api/places/areas', {}).then(
      result => {
        setAreas(result.data.Areas);
      },
      error => {
        console.log(error);
      },
    );
  }, []);

  const AddOptions = props => {
    return (
      <React.Fragment>
        <Button onClick={() => history.push('/submitPlace')}>
          I'm a customer
        </Button>
        <Button onClick={() => history.push('/registerPlace')}>
          I'm a business owner
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div style={{ maxWidth: '1300px', margin: '0px auto' }}>
      <Row justify='center'>
        <Col xs={22} md={22}>
          <Title
            level={1}
            style={{
              color: 'white',
              textAlign: 'center',
              fontSize: '4em',
            }}>
            Gutsein-Gutschein.
          </Title>
        </Col>
      </Row>
      <Row justify='center'>
        <Col
          xs={{
            span: 18,
          }}
          span={16}>
          <div
            className='header-underheader'
            style={{
              textAlign: 'center',
            }}>
            Für einen Gutschein bezahlen sie heute 20% weniger. Den
            Gutsein-Gutschein können sie nach dem Corona-Shutdown in einem der
            unten genannten Geschäfte einlösen. Sie tun Gutes für die arg
            gebeutelten Geschäfte und es wird Ihnen verdankt. Sie helfen mit,
            vielen guten Geschäften die Durststrecke zu überwinden.
            <p style={{ fontSize: '2em' }}>Seien sie so gut.</p>
          </div>
        </Col>
      </Row>
      <Col
        sm={{ span: 20, offset: 2 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 20, offset: 2 }}>
        <div className='main-results'>
          <Row justify='start' style={{ padding: 20 }} align='middle'>
            <Col xs={24} sm={17} md={18} lg={19} flex='auto'>
              <div>
                <AreaContext.Consumer>
                  {value => {
                    return <PlaceFilterDisplay updateArea={value.updateArea} />;
                  }}
                </AreaContext.Consumer>
              </div>
            </Col>
            <Col
              style={{ paddingBottom: '20px', textAlign: 'center' }}
              span={10}
              xs={24}
              md={10}
              offset={0}>
              <Button className='add-button'>Mache HIER mit</Button>
              <p style={{ margin: 5, fontSize: 18 }}>oder</p>
              <Button className='add-button'>Empfehle ein Geschäft</Button>
            </Col>
          </Row>
          {Areas && Areas.length > 0 && (
            <div className='neighborhood-card-container-outer'>
              <AreaCards areas={Areas} hist={history} />
            </div>
          )}
        </div>
      </Col>
      <Col className='footer-content'>
        <Row justify='center' style={{ textAlign: 'center' }}>
          <Col xs={18} md={24}>
            <p>
              “We Grow By Helping Businesses In Need. Accommodate a ‘dime’ to a
              worthy cause this quarantine, for a world you want to live in”.
            </p>
          </Col>
        </Row>
        <Row
          align='middle'
          style={{ textAlign: 'center', marginBottom: 20 }}
          justify='center'>
          <Col xs={6} md={6}>
            <Title level={2}>1.</Title>
            <p>Find a local business</p>
          </Col>
          <Col xs={6} md={6}>
            <Title level={2} className='series-header'>
              2.
            </Title>
            <p>Add it if it's not there yet</p>
          </Col>
          <Col xs={6} md={6}>
            <Title level={2}>3.</Title>
            <p>
              Provide liquidity to businesses by buying gift cards at a discount
              now and benefit later
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            offset={2}
            xs={18}
            sm={18}
            md={9}
            lg={9}
            xl={9}
            style={{ textAlign: 'left' }}>
            <Title level={3}>Our Cause</Title>
            <p>
              There is no question, the detrimental impact the coronavirus
              outbreak is having on small businesses. Due to self-isolation and
              quarantine, liquidity is a severe problem, leading to cut-downs in
              staff or businesses deciding to shut-down for good! <br />
              <br />
              We have to club together, NOW, more than ever, to support and
              empower our small biz friends that are the heartbeat of our local
              communities. Compassionately supporting the makers, movers and
              shakers whom make a positive difference to our everyday lives,
              ensures that the greatness of our communities prevails.
            </p>
          </Col>
          <Col
            offset={2}
            xs={18}
            sm={18}
            md={9}
            lg={9}
            xl={9}
            style={{ textAlign: 'left' }}>
            <Title level={3}>Our Mantra </Title>
            <p>
              Think big, shop small, cherish your local economy and in return it
              will salute you back! <br />
              <br />
              Every small contribution makes a BIG difference, and that’s where
              we come in..
            </p>
          </Col>
        </Row>
        <Row justify='center'>
          <Col
            offset={0}
            xs={18}
            sm={18}
            md={9}
            lg={9}
            xl={9}
            style={{ textAlign: 'center' }}>
            <Title level={3}>What We Do</Title>
            <p>
              We are accommodating the need to support small businesses during
              this unprecedented time, through the ability to purchase gift
              cards. These gift cards are essentially mini-loans, with an added
              benefit as a ‘Thank You’ for your mindful and generous early
              purchase, which you can redeem and enjoy at a later date!
            </p>
          </Col>
        </Row>
      </Col>
    </div>
  );
}

function Header(props) {
  const [FAQVisible, setFAQVisible] = useState(false);
  const history = useHistory();

  const toMain = () => {
    history.push('/');
  };

  return (
    <div className='top-header'>
      <FAQModal
        shouldShow={FAQVisible}
        onClose={() => {
          setFAQVisible(false);
        }}
      />
      <Row
        align='middle'
        style={{ width: '90%', maxWidth: '1100px', margin: '0 auto' }}>
        <Col flex='5.1em'>
          <img
            src={img}
            alt='logo'
            style={{ float: 'left', height: '5em', cursor: 'pointer' }}
            onClick={toMain}></img>
        </Col>
        <Col flex='auto'>
          <div style={{ float: 'right' }}>
            <Popover content={<ShareOptions />}>
              <Button
                shape='round'
                className='header-button'
                style={{ marginRight: '0.4em', marginBottom: '0.2em' }}>
                Sag's weiter!
              </Button>
            </Popover>
            <Button shape='round' className='header-button'>
              Kontakt
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Button,
  Typography,
  message,
} from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import './formStyle.scss';
import GoogleAddressInput from './CustomInputs';

const { Title } = Typography;
const { Option } = Select;

export default function SubmitPlace() {
  const [Industries, setIndustries] = useState([]);
  const [Areas, setAreas] = useState([]);

  useEffect(() => {
    axios.get('/api/places/industries').then(result => {
      setIndustries(result.data.Industries);
    });
    axios.get('/api/places/areas', {}).then(
      result => {
        setAreas(result.data.Areas);
      },
      error => {
        console.log(error);
      },
    );
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 5,
      },
    },
  };

  const SubmitForm = props => {
    const [form] = Form.useForm();
    const hist = useHistory();

    const onFinish = values => {
      axios
        .post('/api/places/submit_new_place', {
          ...values,
        })
        .then(response => {
          message.success('Vorschlag abgesendet');
          hist.push('/');
        })
        .catch(error => {
          message.error('Fehler beim absenden!');
          console.log(error);
        });
    };

    return (
      <Form
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        initialValues={{
          associated: false,
        }}
        scrollToFirstError>
        <Form.Item
          name='placeName'
          label='Firmen Name'
          rules={[
            {
              required: true,
              message: 'Please input the Name!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='placeAddress'
          label='Firmen Adresse'
          rules={[
            {
              required: true,
              message: 'Please input the address!',
            },
          ]}>
          <GoogleAddressInput></GoogleAddressInput>
        </Form.Item>

        <Form.Item name='placeWebsite' label='Firmen Webseite'>
          <Input />
        </Form.Item>

        <Form.Item
          name='area'
          label='Bereich'
          rules={[
            {
              required: true,
              message: 'Please select the area',
            },
          ]}>
          <Select>
            {props.areas.map(ar => {
              return <Select.Option value={ar.key}>{ar.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name='industry'
          label='Art'
          rules={[
            {
              required: true,
              message: 'Please select the type',
            },
          ]}>
          <Select>
            {props.industries.map(ar => {
              return <Select.Option value={ar.key}>{ar.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='submitterName'
          label='Dein Name'
          rules={[
            {
              required: true,
              message: 'Please input your Name!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='email'
          label='Deine E-mail'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='associated'
          valuePropName='checked'
          {...tailFormItemLayout}>
          <Checkbox>Ich bin mit dem Geschäft verbunden.</Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit'>
            Bestätigen
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
      <Row>
        <Title level={1} style={{ color: 'white' }}>
          Empfehle ein Geschäft
        </Title>
      </Row>
      <Row>
        <p style={{ color: 'white' }}>
          Wenn Du ein Gast bist, der ein Geschäft, das Du liebst, unterstützen
          möchte, kannst Du die Informationen dieses Geschäfts in dieses
          Verzeichnis eintragen und andere dazu ermutigen, Geschenkkarten zu
          kaufen, um Dein Geschäft zu unterstützen. Nachdem Du die Informationen
          des Restaurants übermittelt hast, müssen wir die Angaben bestätigen,
          bevor wir sie in das Verzeichnis aufnehmen können. Dies kann bis zu 48
          Stunden dauern.
        </p>
      </Row>
      <div className='main-results' style={{ padding: 15 }}>
        <SubmitForm areas={Areas} industries={Industries}></SubmitForm>
      </div>
    </div>
  );
}

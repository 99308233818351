import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import useScript from '../../lib/useScript';
import Config from '../../Config';

export default function GoogleAddressInput({ value = {}, onChange }) {
  const googleURL =
    'https://maps.googleapis.com/maps/api/js?key=' +
    Config.GoogleAPIKey +
    '&libraries=places';
  const [scriptLoaded] = useScript(googleURL);

  const triggerChange = changedValue => {
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  return (
    <>
      {!scriptLoaded && <div>Loading...</div>}
      {scriptLoaded && (
        <GooglePlacesAutocomplete
          onSelect={selected => {
            value = selected;
            triggerChange(selected);
          }}
          placeholder={'Find the business'}
          inputClassName={'add-link-modal-input'}
          autocompletionRequest={{
            types: ['establishment'],
            componentRestrictions: {
              country: 'ch',
            },
          }}
          types={['establishment']}
        />
      )}
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Typography, Row } from 'antd';
import axios from 'axios';
import { SuggestedPlaceCards } from './SuggestedPlaceCards';
const { Title } = Typography;

function IndustryDropdown(props) {
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setIndustry] = useState();

  useEffect(() => {
    axios.get('/api/places/industries').then(result => {
      setIndustries(result.data.Industries);
      setIndustry(result.data.Industries[0].key);
    });
  }, []);

  return (
    <>
      <Title level={4}>Kategorie:</Title>
      <select
        className='area-picker'
        value={selectedIndustry}
        onChange={event => {
          setIndustry(event.target.value);
          props.updateIndustry(event.target.value);
        }}>
        {industries.map(ind => {
          return (
            <option key={ind.key} value={ind.key}>
              {ind.name}
            </option>
          );
        })}
      </select>
    </>
  );
}

export class AreaCards extends React.Component {
  constructor(props) {
    super(props);

    console.log(props.areas);

    this.state = {
      suggestedPlaces: null,
      selectedArea: props.areas[0],
      selectedIndustry: 'ec',
      offset: 0,
      fetchOffset: 0,
      windowWidth: 0,
      isPlacesLoading: false,
      moreAvailable: true,
    };
    this.ref = React.createRef();
    this.loadSuggestedPlaces(undefined, undefined);
  }
  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  };
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.currentArea !== this.props.currentArea) {
      this.setState({
        loading: true,
        suggestedPlaces: null,
        offset: 0,
      });
    }
  };
  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  updateIndustry = industry => {
    this.setState({ selectedIndustry: industry });
    this.loadSuggestedPlaces(undefined, industry);
  };
  updateArea = area => {
    this.setState({
      selectedArea: area,
      fetchOffset: 0,
      suggestedPlaces: null,
    });

    this.loadSuggestedPlaces(area.key, undefined);
  };

  loadSuggestedPlaces(area, industry) {
    let areaKey = area ? area : this.state.selectedArea.key;
    let industryKey = industry ? industry : this.state.selectedIndustry;

    this.setState({
      isPlacesLoading: true,
    });

    axios
      .get('/api/places/place_suggestions', {
        params: {
          area_id: areaKey,
          industry_id: industryKey,
        },
      })
      .then(result => {
        console.log(result);
        this.setState({
          suggestedPlaces: result.data.Places,
        });
      })
      .finally(
        this.setState({
          isPlacesLoading: false,
        }),
      );
  }

  getCardsForCurrentPage() {
    const areaCards = this.props.areas.slice(this.state.offset).map(area => {
      return (
        <div
          key={area.key}
          className='neighborhood-card'
          style={{ textAlign: 'center' }}>
          <div
            className={
              'neighborhood-card-image' +
              (this.state.selectedArea &&
              this.state.selectedArea.key === area.key
                ? ' neighborhood-card-image-selected'
                : '')
            }
            onClick={event => {
              this.updateArea(area);
            }}>
            <div
              className={
                'neighborhood-card-title' +
                (this.state.selectedArea &&
                this.state.selectedArea.key === area.key
                  ? ' neighborhood-card-title-selected'
                  : '')
              }>
              {area.name}
            </div>
          </div>
        </div>
      );
    });
    return areaCards;
  }
  render() {
    return (
      <>
        <Row justify='center'>
          <IndustryDropdown
            updateIndustry={this.updateIndustry}></IndustryDropdown>
        </Row>
        {this.props.areas && (
          <section
            className='neighborhood-card-container'
            style={{
              maxWidth:
                this.state.windowWidth >= 576
                  ? this.state.windowWidth - 200
                  : this.state.windowWidth,
            }}>
            {this.state.offset !== 0 && (
              <a
                className={
                  'neighborhood-card-arrow neighborhood-card-arrow-left ' +
                  (this.state.offset >= this.props.areas.length - 1
                    ? 'neighborhood-card-arrow-disabled'
                    : '')
                }
                onClick={event => {
                  this.setState({
                    offset: Math.max(0, this.state.offset - 1),
                  });
                }}>
                {'‹'}
              </a>
            )}
            {this.getCardsForCurrentPage()}
            {this.state.offset < this.props.areas.length - 1 && (
              <a
                className={
                  'neighborhood-card-arrow neighborhood-card-arrow-right ' +
                  (this.state.offset >= this.props.areas.length - 1
                    ? 'neighborhood-card-arrow-disabled'
                    : '')
                }
                onClick={event => {
                  this.setState({
                    offset: Math.min(
                      this.props.areas.length - 1,
                      this.state.offset + 1,
                    ),
                  });
                }}>
                ›
              </a>
            )}
          </section>
        )}
        {(this.state.isPlacesLoading ||
          (this.state.suggestedPlaces &&
            this.state.suggestedPlaces.length > 0)) && (
          <SuggestedPlaceCards
            passRef={this.ref}
            suggestedPlaces={this.state.suggestedPlaces}
            selectedArea={this.state.selectedArea}
            moreAvailable={this.state.moreAvailable}
            hist={this.props.hist}
          />
        )}

        {this.state.suggestedPlaces &&
          this.state.suggestedPlaces.length === 0 &&
          this.state.selectedArea && (
            <p>No results for {this.state.selectedArea.name}</p>
          )}
      </>
    );
  }
}

export default AreaCards;
